import React, { Component } from "react";

export default class Contact extends Component {
  render() {
    return (
      <div>
        <section className="colorlib-contact" data-section="contact">
          <div className="colorlib-narrow-content">
            <div className="row">
              <div className="col-md-12">
                <div
                  className="row row-bottom-padded-sm animate-box"
                  data-animate-effect="fadeInLeft">
                  <div className="col-md-12">
                    <div className="contact-desc">
                      <span className="heading-meta">Kontakt</span>
                      <h2 className="colorlib-heading">So kann man uns erreichen</h2>
                      <p>
                        <strong>
                          Privatkindergarten
                          <br />
                          Individuelle Kinderbetreuung
                        </strong>
                      </p>
                      <p>
                        <span className="address">
                          <i className="icon-contacts" />
                          &nbsp;&nbsp;
                          <a
                            href="https://www.google.com/maps/search/?api=1&query=Privatkindergarten+Individuelle+Kinderbetreuung,+Grabenstrasse+20,+8010+Graz"
                            target="_blank"
                            rel="noreferrer">
                            Grabenstrasse 20, 8010 Graz
                          </a>
                          <br />
                        </span>
                        <span className="email">
                          <i className="icon-mail" />
                          &nbsp;&nbsp;
                          <a href="mailto:office@individuelle-kinderbetreuung.at">
                            office@individuelle-kinderbetreuung.at
                          </a>
                          <br />
                        </span>
                        <span className="phone">
                          <i className="icon-phone" />
                          &nbsp;&nbsp;<a href="tel:+436706044490">0670 / 60 444 90</a>
                          <br />
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="colorlib-contact">
          <div className="colorlib-narrow-content">
            <div className="row">
              <div className="col-md-12">
                <div
                  className="row row-bottom-padded-sm animate-box"
                  data-animate-effect="fadeInLeft">
                  <div className="col-md-12">
                    <div className="contact-desc">
                      <span className="heading-meta">Impressum</span>
                      <h2 className="colorlib-heading">Für den Inhalt verantwortlich</h2>
                      <div className="col-md-6 services desc" style={{ marginBottom: 2 + "em" }}>
                        <strong>Verein für Individuelle Kinderbetreuung</strong>
                        <br />
                        Grabenstraße 20, 8010 Graz
                        <br />
                        office@individuelle-kinderbetreuung.at
                        <br />
                        +43 670 60 444 90
                        <br />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}
