import React, { Component } from "react";

export default class Food extends Component {
  render() {
    return (
      <div>
        <section className="colorlib-about" data-section="food">
          <div className="colorlib-narrow-content">
            <div className="row">
              <div className="col-md-12">
                <div
                  className="row row-bottom-padded-sm animate-box"
                  data-animate-effect="fadeInLeft">
                  <div className="col-md-12">
                    <div className="about-desc">
                      <span className="heading-meta">Essen</span>
                      <h2 className="colorlib-heading">Bei uns wird täglich frisch gekocht</h2>
                      <p>
                        Wir legen großen Wert auf eine gesunde und ausgewogene Ernährung. Die
                        Mahlzeiten sind vegetarisch und werden aus frischen, saisonalen und
                        regionalen Produkten zubereitet. Unsere Kinder haben die Möglichkeit in der
                        Küche mit zu helfen.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}
