import React, { Component } from "react";

export default class Structural extends Component {
  render() {
    return (
      <div>
        <section className="colorlib-about" data-section="structural">
          <div className="colorlib-narrow-content">
            <div className="row">
              <div className="col-md-12">
                <div
                  className="row row-bottom-padded-sm animate-box"
                  data-animate-effect="fadeInLeft">
                  <div className="col-md-12">
                    <div className="about-desc">
                      <span className="heading-meta">Strukturelles</span>
                      <h2 className="colorlib-heading">Wissenswertes über unsere Struktur</h2>
                      <p>
                        Der Träger unseres eingruppigen Privatkindergartens ist der Verein für
                        Individuelle Kinderbetreuung.<br></br>
                        Unser Kindergarten ist Tarifpartner der Stadt Graz. Dadurch entsprechen die
                        Kosten der offiziellen Beitragstabelle.
                      </p>
                      <h3 className="colorlib-heading">Betreuungszeiten</h3>
                      <ul>
                        <li>Mo-Fr 7:30-13:30 Uhr Halbtag mit Mittagessen.</li>
                        <li>Mo-Fr 7:30-15:30 Uhr Ganztag mit Mittagessen.</li>
                        <li>
                          Die Schließzeiten orientieren sich an den Schulferien (Weihnachts-,
                          Semester- und Osterferien).
                        </li>
                      </ul>
                      <h3 className="colorlib-heading">Sommerkindergarten im Juli</h3>
                      <ul>
                        <li>4 Wochen, Mo-Fr 7:30-13:30 Uhr / 7:30-15:30 Uhr mit Mittagessen</li>
                      </ul>
                      <h3 className="colorlib-heading">Sommerkindergarten im August</h3>
                      <ul>
                        <li>4 Wochen, Mo-Fr 7:30-12:30 Uhr ohne Mittagessen</li>
                        <li>
                          Die letzte Ferienwoche ist wegen pädagogischer Vorbereitungen geschlossen.
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}
