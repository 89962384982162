import React, { Component } from "react";
import "./sidebar.css";

export default class Sidebar extends Component {
  render() {
    return (
      <div>
        <div>
          <nav
            ref="#navbar"
            className="js-colorlib-nav-toggle colorlib-nav-toggle"
            data-toggle="collapse"
            data-target="#navbar"
            aria-controls="navbar">
            <i />
          </nav>
          <aside id="colorlib-aside" className="border js-fullheight">
            <div className="text-center">
              <div
                className="author-img"
                style={{
                  backgroundImage: "url(/images/logo-neu-kecke-schnecke.png)"
                }}
              />
              <h1 id="colorlib-logo">
                <a href="index.html">Privatkindergarten</a>
              </h1>
              <h1 id="colorlib-logo-below">
                <a href="index.html"> Individuelle Kinderbetreuung</a>
              </h1>
              <span className="phone">
                <i className="icon-phone" /> 0670 / 60 444 90
              </span>
            </div>
            <nav id="colorlib-main-menu" role="navigation" className="navbar">
              <div id="navbar" className="collapse">
                <ul>
                  <li className="active">
                    <a href="#home" data-nav-section="home">
                      Home
                    </a>
                  </li>
                  <li>
                    <a href="#about" data-nav-section="about">
                      Über uns
                    </a>
                  </li>
                  <li>
                    <a href="#food" data-nav-section="food">
                      Essen
                    </a>
                  </li>
                  <li>
                    <a href="#team" data-nav-section="team">
                      Team
                    </a>
                  </li>
                  <li>
                    <a href="#structural" data-nav-section="structural">
                      Strukturelles
                    </a>
                  </li>
                  <li>
                    <a href="#contact" data-nav-section="contact">
                      Kontakt / Impressum
                    </a>
                  </li>
                </ul>
              </div>
            </nav>
            <nav id="colorlib-main-menu"></nav>
            <div className="colorlib-footer">
              <p>
                <small>
                  Website made with <i className="icon-heart" aria-hidden="true" /> and{" "}
                  <i className="icon-coffee" aria-hidden="true" />
                  <br />
                  Thanks{" "}
                  <a href="https://colorlib.com" target="_blank" rel="noopener noreferrer">
                    Colorlib
                  </a>{" "}
                  for inspiration
                </small>
              </p>
            </div>
          </aside>
        </div>
      </div>
    );
  }
}
